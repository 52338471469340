.hidden {
  display: none !important;
}


body,
html {
  color: #353535;
  font-family: 'Roboto', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.mat-mdc-form-field,
.mat-mdc-form-field:hover,
.mat-mdc-form-field:focus,
.mat-mdc-form-field.mat-focused {
  background-color: transparent !important;
}

.mat-mdc-form-field * {
  background-color: transparent !important;
}

.mat-mdc-form-field:hover *,
.mat-mdc-form-field:focus *,
.mat-mdc-form-field.mat-focused * {
  background-color: transparent !important;
}

.mat-mdc-form-field:not(.mdc-text-field--disabled):hover,
.mat-mdc-form-field:not(.mdc-text-field--disabled):focus,
.mat-mdc-form-field:not(.mdc-text-field--disabled).mat-focused,
.mat-mdc-form-field:not(.mdc-text-field--disabled):hover *,
.mat-mdc-form-field:not(.mdc-text-field--disabled):focus *,
.mat-mdc-form-field:not(.mdc-text-field--disabled).mat-focused * {
  background-color: transparent !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.lk-page__container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 2px 6px 0px rgba(90, 131, 238, 0.25);
  padding: 55px;
}

.lk-page__header {
  margin-bottom: 20px;
  margin-right: 13px;
}

.lk-page__header h1 {
  color: #353535;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

// Buttons styles override
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-raised-button.mat-accent {
  color: white !important;
  --mdc-fab-icon-color: white !important;
  --mat-mdc-fab-color: white !important;

  --mdc-filled-button-label-text-color: white !important;
  --mdc-protected-button-label-text-color: white !important;
}

.light .mat-mdc-raised-button.mat-primary,
.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff !important;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1) !important;
}

mat-error,
mat-hint {
  font-size: 12px;
  color: #ff0000;
  line-height: 14px;
  margin-top: 4px;
}

.mat-form-field {
  margin-bottom: 12px;
}
.form-row {
  margin-bottom: 50px;
}

.hide-tabs .mat-mdc-tab-labels,
::ng-deep .hide-tabs .mat-mdc-tab-labels,
:ng-deep .hide-tabs .mat-mdc-tab-labels {
  display: none !important;
}

.hide-tabs .mat-mdc-tab-labels {
  display: none !important;
}
.hide-tabs .mat-mdc-tab-label-container {
  display: none !important;
}
.hide-tabs .mat-mdc-tab-label-container .mat-tab-label {
  display: none !important;
}

::ng-deep .hide-tabs .mat-mdc-tab-label-container,
:ng-deep .hide-tabs .mat-mdc-tab-label-container {
  display: none !important;
}
